import LoginLayout from '~/templates/login/LoginLayout'
import React, { useEffect } from 'react'
import { NextPageWithLayout } from '~/pages/_app'
import { useRouter } from 'next/router'

const DefaultLoginPage: NextPageWithLayout = () => {
  const router = useRouter()
  useEffect(() => {
    const isEnglish = router.locale === 'en' || router.locales?.find((locale) => locale === 'en')
    const locale = isEnglish ? 'en' : 'ko'
    router.replace(`/${locale}/login`)
  }, [router])
  return null
}

DefaultLoginPage.getLayout = (page) => {
  return <LoginLayout>{page}</LoginLayout>
}
export default DefaultLoginPage
