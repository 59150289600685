import React, { FC, PropsWithChildren } from 'react'
import ToastContextProvider from '~/templates/ToastContextProvider'
import Toast from '~/templates/Toast'
import { Box } from '~/components'
import DowntimeOverlay from '~/templates/DowntimeOverlay'

const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div id="dialog-target">
      <ToastContextProvider>
        <Box
          position="fixed"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          backgroundColor="gray-100"
          overflow="auto"
        >
          {children}
          <Toast />
        </Box>
        <DowntimeOverlay />
      </ToastContextProvider>
    </div>
  )
}

export default LoginLayout
